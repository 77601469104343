import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { makeStyles } from "tss-react/mui";
import {
    Box, Toolbar, Typography, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper
} from "@mui/material";
import { actionCreators } from "../store/LogState";
import { useNavigate } from "react-router-dom";
import { Layout, Headerbar } from "../Layout";
import { getCurrentUser } from "../services/CurrentUserResolver";

const useStyles = makeStyles({ name: "Log" })((theme, params, classes) => ({
    logPage: {
        [`& .${classes.logList}`]: {
            marginTop: "35px",
            marginBottom: "35px"
        }
    },
    logList: {

    },
    rowAlt: {
        backgroundColor: theme.palette.action.hover,
    }
}));

const LogPage = props => {
    const {
        isLoading, signOut, resetState, loadLogs, logs
    } = props;
    const { classes } = useStyles();
    const navigate = useNavigate();

    useEffect(() => {
        if (getCurrentUser() == null) {
            navigate("/auth/sign-in");
            return;
        }

        loadLogs();

        return () => {
            resetState();
        };
    }, []);

    let lastGameId = 0;
    let lastClassName = "";
    const classNames = [];
    for (let index = 0; index < logs.length; ++index) {
        const log = logs[index];
        if (lastGameId !== log.gameId) {
            lastGameId = log.gameId;
            lastClassName = lastClassName === "" ? classes.rowAlt : ""; 
        }

        classNames[index] = lastClassName;
    }

    const playCounts = {};
    for (const log of logs) {
        if(!playCounts[log.gameTitle]) {
            playCounts[log.gameTitle] = 0;
        }
        playCounts[log.gameTitle] += 1;
    }

    return (
        <>
            <Headerbar signOut={signOut} />
            <Layout>
                <Toolbar id="back-to-top-anchor" />
                <Box className={classes.logPage}>
                    {isLoading && (
                        <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", paddingTop: "24px" }}>
                            <CircularProgress color="secondary" />
                        </Box>
                    )}
                    <Typography variant="h6">Брой изигравания:</Typography>
                    {!isLoading && logs.length <= 0 && (
                        <Typography variant="subtitle1" color="text.secondary" component="div">Няма изиграни игри.</Typography>
                    )}
                    {!isLoading && logs.length > 0 && (
                        <TableContainer className={classes.logList} component={Paper}>
                            <Table sx={{ width: "100%" }} aria-label="log table">
                                <TableBody>
                                    {
                                        Object.keys(playCounts).map((gameTitle) => (
                                            <TableRow
                                                key={gameTitle}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {gameTitle}
                                                </TableCell>
                                                <TableCell>
                                                    {playCounts[gameTitle]}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}  

                    <Typography variant="h6">История:</Typography>
                    {!isLoading && logs.length <= 0 && (
                        <Typography variant="subtitle1" color="text.secondary" component="div">Няма изиграни игри.</Typography>
                    )}
                    {!isLoading && logs.length > 0 && (
                        <TableContainer className={classes.logList} component={Paper}>
                            <Table sx={{ width: "100%" }} aria-label="log table">
                                <TableBody>
                                    {
                                        logs.map((log, index) => (
                                            <TableRow
                                                className={classNames[index]}
                                                key={log.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {log.gameTitle}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </Box>
            </Layout>
        </>
    );
};

export default connect(
    (state) => state.log,
    (dispatch) => bindActionCreators(actionCreators, dispatch)
)(LogPage)