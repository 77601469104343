import ApiClient from "../services/ApiClient";
import { clearCurrentUser } from "../services/CurrentUserResolver";

const loadLogsType = "REQUEST_LOG";
const completeLoadLogsType = "COMPLETE_REQUEST_LOG";
const errorType = "LOG_ERROR";

const signOutType = "SING_OUT_TYPE";
const resetStateType = "RESET_LOG_STATE";


const initialState = {
    isLoading: false,
    logs: []
};


export const actionCreators = {
    signOut: (navigate) => async (dispatch) => {
        clearCurrentUser();
        navigate("/auth/sign-in");
        dispatch({ type: signOutType });
    },
    resetState: () => async (dispatch) => {
        dispatch({ type: resetStateType });
    },
    loadLogs: () => async (dispatch) => {
        dispatch({ type: loadLogsType });        
        const { data: logs } = await ApiClient.get(`/api/log`, dispatch);
        dispatch({ type: completeLoadLogsType, logs });
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === signOutType || action.type === resetStateType) {
        return {
            ...initialState
        };
    }

    if (action.type === loadLogsType) {
        return {
            ...state,
            isLoading: true
        };
    }

    if (action.type === completeLoadLogsType) {
        return {
            ...state,
            isLoading: false,
            logs: action.logs
        };
    }

    if (action.type === errorType) {
        return {
            ...state,
            isLoading: false
        };
    }

    return state;
};