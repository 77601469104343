import axios from "axios";
import { getCurrentUser } from "./CurrentUserResolver";

export const requestHandler = (config) => {
    config.headers.Authorization = getCurrentUser();
	return config;
};

const errorHandler = error => {
    const response = error.response;
    console.error(response.status, response.data);
    alert("Something happened. If the problem persists, please contact support.");

    return Promise.reject({ ...error });
};

export const successHandler = (response) => {
    return response;
};

const api = axios.create({
    baseURL: process.env.REACT_APP_API_ROOT,
    headers: {
        get: {
           "Cache-Control": "no-cache",
           "Pragma": "no-cache",
           "Expires": "Sat, 01 Jan 2000 00:00:00 GMT",
           "If-Modified-Since": "0"
        }
    }
});

const defaultTimeout = 20000; // 20 seconds
class ApiClient {
    constructor() {
        api.interceptors.request.use(
            request => requestHandler(request)
        );
        api.interceptors.response.use(
            response => successHandler(response),
            error => errorHandler(error)
        );

        api.defaults.timeout = defaultTimeout;
    }

    defaultOptions = {};

    get(url, storeDispatch, options = {}) {
        return api.get(url, { ...this.defaultOptions, ...options, store: { dispatch: storeDispatch } });
    }

    post(url, data, storeDispatch, options = {}) {
        return api.post(url, data, { ...this.defaultOptions, ...options, store: { dispatch: storeDispatch } });
    }

    put(url, data, storeDispatch, options = {}) {
        return api.put(url, data, { ...this.defaultOptions, ...options, store: { dispatch: storeDispatch } });
    }

    delete(url, storeDispatch, data, options = {}) {
        return api.delete(url, { ...this.defaultOptions, ...options, store: { dispatch: storeDispatch }, data: data });
    }

    cancelTokenSource() {
        return axios.CancelToken.source();
    }
}

export default new ApiClient();