import React from "react"
import { Routes, Route } from "react-router-dom";
import GamesPage from "./pages/GamesPage";
import LogPage from "./pages/LogPage";
import SignInPage from "./pages/SignInPage";

export const App = props => {
    return (
        <Routes>
            <Route path="/" element={<GamesPage />} />
            <Route path="/log" element={<LogPage />} />
            <Route path="/auth/sign-in" element={<SignInPage />} />
        </Routes>
    );
};
