export const getCurrentUser = user => {
	return localStorage.getItem('currentUser');
};

export const setCurrentUser = user => {
	localStorage.setItem('currentUser', user);
};

export const clearCurrentUser = user => {
	localStorage.removeItem('currentUser');
};