import ApiClient from "../services/ApiClient";
import { setCurrentUser } from "../services/CurrentUserResolver";

const requestClearUserType = "REQUEST_CLEAR_USER";
const completeClearUserType = "COMPLETE_CLEAR_USER";

export const availableUsers = [
    "ivan_petrov",
    "georgi_georgiev",
    "maria_dimitrova",
    "patar_petrov",
    "dimitar_krastev",
    "gergana_ivanova"
];

const initialState = {
    loading: false
};

export const actionCreators = {
    signIn: (user, navigate) => async (dispatch) => {
        setCurrentUser(user);
        navigate("/");
    },
    clearUser: (username) => async (dispatch) => {
        dispatch({ type: requestClearUserType });

        try {
            await ApiClient.post(`/api/user/clear`, { username }, dispatch);
            alert("Историята на потребителя е изтрита.");
            dispatch({ type: completeClearUserType });
        } catch (err) {
            dispatch({ type: completeClearUserType });
        }
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    if(action.type === requestClearUserType) {
        return {
            ...state,
            loading: true
        };
    }

    if(action.type === completeClearUserType) {
        return {
            ...state,
            loading: false
        };
    }

    return state;
};