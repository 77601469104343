import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { makeStyles } from "tss-react/mui";
import { Box, Button, FormControl, Typography, Select, MenuItem, Paper } from "@mui/material";
import { actionCreators, availableUsers } from "../store/AppState";
import { useNavigate } from "react-router-dom";
import { Layout } from "../Layout";
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = makeStyles({ name: "SignIn" })((theme, params, classes) => ({
	formWrapper: {
		maxWidth: "450px",
		marginLeft: "auto",
		marginRight: "auto",
		padding: theme.spacing(4)
	}
}));

const SignInPage = props => {
	const { signIn, clearUser, loading } = props;
	const { classes } = useStyles();
	const navigate = useNavigate();
	const [selectedUser, setSelectedUser] = useState(availableUsers[0]);

	const handleChange = event => {
		setSelectedUser(event.target.value);
	};

	const handleClearUser = () => {
		if(window.confirm(`Наистина ли искате да изтриете всички оценки на ${selectedUser}?`)) {
			clearUser(selectedUser);
		}
	};

	return (
		<Layout>
			<Box sx={{ width: "100%", paddingTop: "200px" }}>
				<Paper className={classes.formWrapper}>
					<Typography variant="h6" sx={{ paddingBottom: "15px" }}>Избери потребител:</Typography>
					<FormControl fullWidth>
						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={selectedUser}
							onChange={handleChange}
							disabled={loading}
						>
							{
								availableUsers.map(user => (
									<MenuItem key={user} value={user}>{user}</MenuItem>
								))
							}
						</Select>
					</FormControl>
					<Box sx={{ marginTop: "20px", display: "flex" }}>
						<Box sx={{ flexGrow: 1 }}>
							<Button onClick={() => signIn(selectedUser, navigate)} variant="contained" disabled={loading} >Влез</Button>
						</Box>						
						<Button onClick={handleClearUser} variant="contained" color="secondary" startIcon={<DeleteIcon />} disabled={loading} >Изчисти</Button>
					</Box>					
				</Paper>				
			</Box>
		</Layout>
	);
};

export default connect(
	(state) => state.app,
	(dispatch) => bindActionCreators(actionCreators, dispatch)
)(SignInPage)