import ApiClient from "../services/ApiClient";
import { clearCurrentUser } from "../services/CurrentUserResolver";

const loadGamesType = "REQUEST_GAMES_LIST";
const filterGamesType = "FILTER_GAMES_LIST";
const filterByLetterType = "FILTER_BY_LETTER_GAMES_LIST";
const clearFilterType = "CLEAR_FILTER_TYPE";
const completeLoadGamesType = "COMPLETE_REQUEST_GAMES_LIST";

const loadRecommendationsType = "REQUEST_RECOMMENDATIONS";
const completeLoadRecommendationsType = "COMPLETE_REQUEST_RECOMMENDATIONS";

const playGameType = "REQUEST_PLAY_GAME";
const completePlayGameType = "COMPLETE_PLAY_GAME";

const playRecommendedGameType = "REQUEST_PLAY_RECOMMENDED_GAME";
const completePlayRecommendedGameType = "COMPLETE_PLAY_RECOMMENDED_GAME";

const signOutType = "SING_OUT_TYPE";
const resetStateType = "RESET_GAMES_STATE";


const initialState = {
    loading: false,
    games: [],
    recommendedGames: [],
    playedGame: null,
    isGamesLoading: false,
    isRecommendedGamesLoading: false,
    offset: 0,
    totalCount: 0,
    filter: "",
    filterLetter: ""
};
const itemsPerPage = 24;
const getGames = async (filter, filterLetter, offset, totalCount, dispatch) => {
    try {
        const { data: gameList } = await ApiClient.get(`/api/games`, dispatch, { params: { offset: offset, count: itemsPerPage, filter: filter, filterLetter: filterLetter } });
        return gameList;
    } catch (err) {
        return {
            offset: offset,
            totalCount: totalCount,
            games: []
        };
    }    
};

const getRecommendations = async (dispatch, getState) => {
    dispatch({ type: loadRecommendationsType });
    try {
        const { data: gameList } = await ApiClient.get(`/api/recommendations`, dispatch);
        dispatch({ type: completeLoadRecommendationsType, gameList });
    } catch (err) {
        dispatch({ type: completeLoadRecommendationsType, gameList: { games: getState().games.recommendedGames } });
    }
};

export const actionCreators = {
    signOut: (navigate) => async (dispatch) => {
        clearCurrentUser();
        navigate("/auth/sign-in");
        dispatch({ type: signOutType });
    },
    resetState: () => async (dispatch) => {
        dispatch({ type: resetStateType });
    },
    loadGames: () => async (dispatch, getState) => {
        const { filter, filterLetter, offset, totalCount } = getState().games;

        dispatch({ type: loadGamesType });        
        const gameList = await getGames(filter, filterLetter, offset, totalCount, dispatch);
        dispatch({ type: completeLoadGamesType, gameList });
    },
    filterGames: (filter) => async (dispatch) => {
        dispatch({ type: filterGamesType, filter });        
        const gameList = await getGames(filter, "", 0, 0, dispatch);
        dispatch({ type: completeLoadGamesType, gameList });
    },
    filterByLetter: (filterLetter) => async (dispatch) => {
        dispatch({ type: filterByLetterType, filterLetter });        
        const gameList = await getGames("", filterLetter, 0, 0, dispatch);
        dispatch({ type: completeLoadGamesType, gameList });
    },
    clearFilter: () => async (dispatch) => {
        dispatch({ type: clearFilterType });        
        const gameList = await getGames("","", 0, 0, dispatch);
        dispatch({ type: completeLoadGamesType, gameList });
    },
    loadRecommendedGames: () => async (dispatch, getState) => {
        await getRecommendations(dispatch, getState);
    },
    playGame: (gameId) => async (dispatch, getState) => {
        dispatch({ type: playGameType, gameId });

        try {
            const { data: playCount } = await ApiClient.post(`/api/play`, { gameId }, dispatch);
            dispatch({ type: completePlayGameType, playCount });
        } catch (err) {
            dispatch({ type: completePlayGameType, playCount: getState().games.find(g => g.id === gameId) });
        }        

        await getRecommendations(dispatch, getState);
    },
    playRecommendedGame: (gameId) => async (dispatch, getState) => {
        dispatch({ type: playRecommendedGameType, gameId });

        try {
            const { data: playCount } = await ApiClient.post(`/api/play`, { gameId }, dispatch);
            dispatch({ type: completePlayRecommendedGameType, playCount });
        } catch (err) {
            dispatch({ type: completePlayRecommendedGameType, playCount: getState().recommendedGames.find(g => g.id === gameId) });
        }        

        await getRecommendations(dispatch, getState);
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === signOutType || action.type === resetStateType) {
        return {
            ...initialState
        };
    }

    if (action.type === loadGamesType) {
        return {
            ...state,
            isGamesLoading: true
        };
    }

    if (action.type === filterGamesType) {
        return {
            ...state,
            isGamesLoading: true,
            games: [],
            offset: 0,
            totalCount: 0,
            filterLetter: "",
            filter: action.filter
        };
    }

    if (action.type === clearFilterType) {
        return {
            ...state,
            isGamesLoading: true,
            games: [],
            offset: 0,
            totalCount: 0,
            filterLetter: "",
            filter: ""
        };
    }

    if (action.type === filterByLetterType) {
        return {
            ...state,
            isGamesLoading: true,
            games: [],
            offset: 0,
            totalCount: 0,
            filterLetter: action.filterLetter,
            filter: ""
        };
    }

    if (action.type === completeLoadGamesType) {
        return {
            ...state,
            isGamesLoading: false,
            games: [ ...state.games, ...action.gameList.games ],
            offset: action.gameList.offset,
            totalCount: action.gameList.totalCount,
            filter: action.gameList.filter,
            filterLetter: action.gameList.filterLetter
        };
    }

    if (action.type === loadRecommendationsType) {
        return {
            ...state,
            isRecommendedGamesLoading: true
        };
    }

    if (action.type === completeLoadRecommendationsType) {
        return {
            ...state,
            isRecommendedGamesLoading: false,
            recommendedGames: action.gameList.games
        };
    }

    if (action.type === playGameType) {
        return {
            ...state,
            games: state.games.map(game => {
                if (game.id === action.gameId) {
                    return {
                        ...game,
                        isLoading: true
                    };
                }

                return game;
            })
        };
    }

    if (action.type === completePlayGameType) {
        return {
            ...state,
            games: state.games.map(game => {
                if (game.id === action.playCount.id) {
                    return {
                        ...game,
                        isLoading: false,
                        playCount: action.playCount.playCount
                    };
                }

                return game;
            }),
            playedGame: state.games.find(g => g.id === action.playCount.id)
        };
    }

    if (action.type === playRecommendedGameType) {
        return {
            ...state,
            recommendedGames: state.recommendedGames.map(game => {
                if (game.id === action.gameId) {
                    return {
                        ...game,
                        isLoading: true
                    };
                }

                return game;
            })
        };
    }

    if (action.type === completePlayRecommendedGameType) {
        return {
            ...state,
            recommendedGames: state.games.map(game => {
                if (game.id === action.playCount.id) {
                    return {
                        ...game,
                        isLoading: false
                    };
                }

                return game;
            }),
            playedGame: state.recommendedGames.find(g => g.id === action.playCount.id)
        };
    }

    return state;
};