import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { makeStyles } from "tss-react/mui";
import {
	Box, Button, Toolbar, Typography, Grid,
	Card, CardContent, CardMedia, TextField, Snackbar, Alert,
	CircularProgress, useScrollTrigger, Fab, Fade, CardActions
} from "@mui/material";
import { actionCreators } from "../store/GamesState";
import { useNavigate } from "react-router-dom";
import { Layout, Headerbar } from "../Layout";
import { getCurrentUser } from "../services/CurrentUserResolver";
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const useStyles = makeStyles({ name: "Games" })((theme, params, classes) => ({
	gamePage: {
		[`& .${classes.gameList}`]: {
			paddingTop: "80px"
		}
	},
	gameList: {

	}
}));

const PlaySnackbar = props => {
	const { playedGame } = props; 
	const [snackPack, setSnackPack] = React.useState([]);
	const [open, setOpen] = React.useState(false);
	const [messageInfo, setMessageInfo] = React.useState(undefined);

	React.useEffect(() => {
		if(playedGame){
			setSnackPack((prev) => [...prev, {message: `Изиграна ${playedGame.title}` , key: new Date().getTime() }]);
		}		
	}, [playedGame]);
  
	React.useEffect(() => {
	  if (snackPack.length && !messageInfo) {
		// Set a new snack when we don't have an active one
		setMessageInfo({ ...snackPack[0] });
		setSnackPack((prev) => prev.slice(1));
		setOpen(true);
	  } else if (snackPack.length && messageInfo && open) {
		// Close an active snack when a new one is added
		setOpen(false);
	  }
	}, [snackPack, messageInfo, open]);
  
	const handleClose = (event, reason) => {
	  if (reason === 'clickaway') {
		return;
	  }
	  setOpen(false);
	};
  
	const handleExited = () => {
	  setMessageInfo(undefined);
	};
  
	return (
	  <Snackbar
		  key={messageInfo ? messageInfo.key : undefined}
		  open={open}
		  anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		  }}
		  autoHideDuration={6000}
		  onClose={handleClose}
		  TransitionProps={{ onExited: handleExited }}
		>
			<Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
				{messageInfo ? messageInfo.message : undefined}
        	</Alert>
		</Snackbar>
	);
  }

const ScrollTop = props => {
	const { children } = props;
	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 250,
	});

	const handleClick = (event) => {
		const anchor = (event.target.ownerDocument || document).querySelector(
			'#back-to-top-anchor',
		);

		if (anchor) {
			anchor.scrollIntoView({
				block: 'center',
			});
		}
	};

	return (
		<Fade in={trigger}>
			<Box
				onClick={handleClick}
				role="presentation"
				sx={{ position: 'fixed', bottom: 16, right: 16 }}
			>
				{children}
			</Box>
		</Fade>
	);
}

const GameItem = props => {
	const { game, playGame } = props;

	return (
		<Grid item xs={4} sx={{ width: "100%" }}>
			<Card sx={{ height: "100%" }}>
				<CardMedia
					component="img"
					sx={{ width: "100%", height: "235px" }}
					image={game.imageUrl}
					alt={game.title + " front"}
				/>
				<CardContent>
					<Typography gutterBottom variant="h5" component="div">
						{game.title}
					</Typography>
					<Typography variant="body2" color="text.secondary">
						{game.genres.join(", ")}
					</Typography>
				</CardContent>
				<CardActions>
					<Button disabled={game.isLoading} onClick={() => playGame(game.id)} size="small">Играй</Button>
				</CardActions>
			</Card>
		</Grid>
	);
};

const GameList = props => {
	const {
		loadGames, games, playGame, isGamesLoading, offset, totalCount
	} = props;

	return (
		<>
			<Grid container spacing={2}>
				{ games.map(game => (<GameItem key={game.id} game={game} playGame={playGame} />)) }
			</Grid>				
			{
				isGamesLoading
					? (
						<Box sx={{ display: 'flex', alignItems: "top", justifyContent: "center", width: "100%", paddingTop: "24px" }}>
							<CircularProgress color="primary" />
						</Box>
					)
					: offset < totalCount ? (
						<Box sx={{ display: 'flex', alignItems: "top", justifyContent: "center", width: "100%", paddingTop: "24px" }}>
							<Button onClick={() => loadGames()} variant="contained" color="primary" sx={{ maxHeight: "37px" }}>Зареди още</Button>
						</Box>
					) : (
						<Box sx={{ display: 'flex', alignItems: "top", justifyContent: "center", width: "100%", paddingTop: "24px" }}>
							<Typography variant="subtitle1" color="text.secondary" component="div">
								{games.length > 0 ? "" : "Няма намерени резултати."}
							</Typography>
						</Box>
					)
			}
		</>
	);
};

const RecommendedGameItem = props => {
	const { game, playGame } = props;

	return (
		<Box sx={{ width: "100%", marginTop: "16px" }}>
			<Card sx={{ display: 'flex', backgroundColor: "transparent" }}>
				<CardMedia
					component="img"
					sx={{ width: 151, height: "151px" }}
					image={game.imageUrl}
					alt={game.title + " front"}
				/>
				<Box sx={{ display: 'flex', flexDirection: 'column' }}>
					<CardContent sx={{ flex: '1 0 auto' }}>
						<Typography component="div" variant="h5">
							{game.title}
						</Typography>
						<Typography variant="subtitle1" color="text.secondary" component="div">
							{game.genres.join(", ")}
						</Typography>
					</CardContent>
					<CardActions>
						<Button color="secondary" disabled={game.isLoading} onClick={() => playGame(game.id)} size="small">Играй</Button>
					</CardActions>
				</Box>
			</Card>
		</Box>
	);
};

const RecommendedGameList = props => {
	const {
		recommendedGames, isRecommendedGamesLoading, playGame
	} = props;

	if (isRecommendedGamesLoading) {
		return (
			<Box sx={{ display: 'flex', alignItems: "center", justifyContent: "center", width: "100%", paddingTop: "24px" }}>
				<CircularProgress color="secondary" />
			</Box>
		);
	}

	if (recommendedGames == null || recommendedGames.length <= 0) {
		return (
			<Typography variant="subtitle1" color="text.secondary" component="div" sx={{ paddingTop: "12px" }}>Изиграй поне две различни игри.</Typography>
		);
	}

	return (
		<>
			{
				recommendedGames.map(game => (<RecommendedGameItem key={game.id} game={game} playGame={playGame} />))
			}
		</>
	);
};

const GameFilter = props => {
	const { filterGames, filter, isGamesLoading, filterLetter, filterByLetter, clearFilter } = props;
	const [innerFilter, setInnerFilter] = useState(filter);
	const handleChange = event => {
		setInnerFilter(event.target.value);
	};
	const handleSubmit = e => {
		e.preventDefault();

		if (!isGamesLoading) {
			filterGames(innerFilter);
		}
	};

	useEffect(() => {
		setInnerFilter(filter);
	}, [filter]);

	return (
		<>
			<form onSubmit={handleSubmit}>
				<Box sx={{ display: "flex" }}>

					<TextField
						id="game-filter"
						value={innerFilter}
						onChange={handleChange}
						sx={{ flexGrow: 1 }}
						size="small"
					/>
					<Button type="submit" disabled={isGamesLoading} variant="contained" sx={{ marginLeft: "20px" }} startIcon={<SearchIcon />} >Търси</Button>

				</Box>
			</form>
			<Box sx={{ display: "flex", width: "100%", justifyContent: "center", paddingTop: "8px" }}>
				<Button variant="text" color={filterLetter === "" && filter === "" ? "secondary" : "primary"} onClick={() => clearFilter()}>ВСИЧКИ</Button>
				<Button variant="text" color={filterLetter === "#" ? "secondary" : "primary"} onClick={() => filterByLetter("#")}>#</Button>
				<Button variant="text" color={filterLetter === "A" ? "secondary" : "primary"} onClick={() => filterByLetter("A")}>А</Button>
				<Button variant="text" color={filterLetter === "B" ? "secondary" : "primary"} onClick={() => filterByLetter("B")}>B</Button>
				<Button variant="text" color={filterLetter === "C" ? "secondary" : "primary"} onClick={() => filterByLetter("C")}>C</Button>
				<Button variant="text" color={filterLetter === "D" ? "secondary" : "primary"} onClick={() => filterByLetter("D")}>D</Button>
				<Button variant="text" color={filterLetter === "E" ? "secondary" : "primary"} onClick={() => filterByLetter("E")}>E</Button>
				<Button variant="text" color={filterLetter === "F" ? "secondary" : "primary"} onClick={() => filterByLetter("F")}>F</Button>
				<Button variant="text" color={filterLetter === "G" ? "secondary" : "primary"} onClick={() => filterByLetter("G")}>G</Button>
				<Button variant="text" color={filterLetter === "H" ? "secondary" : "primary"} onClick={() => filterByLetter("H")}>H</Button>
				<Button variant="text" color={filterLetter === "I" ? "secondary" : "primary"} onClick={() => filterByLetter("I")}>I</Button>
				<Button variant="text" color={filterLetter === "G" ? "secondary" : "primary"} onClick={() => filterByLetter("J")}>J</Button>
				<Button variant="text" color={filterLetter === "K" ? "secondary" : "primary"} onClick={() => filterByLetter("K")}>K</Button>
				<Button variant="text" color={filterLetter === "L" ? "secondary" : "primary"} onClick={() => filterByLetter("L")}>L</Button>
			</Box>
			<Box sx={{ display: "flex", width: "100%", justifyContent: "center", paddingTop: "8px" }}>
				<Button variant="text" color={filterLetter === "M" ? "secondary" : "primary"} onClick={() => filterByLetter("M")}>M</Button>
				<Button variant="text" color={filterLetter === "N" ? "secondary" : "primary"} onClick={() => filterByLetter("N")}>N</Button>
				<Button variant="text" color={filterLetter === "O" ? "secondary" : "primary"} onClick={() => filterByLetter("O")}>O</Button>
				<Button variant="text" color={filterLetter === "P" ? "secondary" : "primary"} onClick={() => filterByLetter("P")}>P</Button>
				<Button variant="text" color={filterLetter === "Q" ? "secondary" : "primary"} onClick={() => filterByLetter("Q")}>Q</Button>
				<Button variant="text" color={filterLetter === "R" ? "secondary" : "primary"} onClick={() => filterByLetter("R")}>R</Button>
				<Button variant="text" color={filterLetter === "S" ? "secondary" : "primary"} onClick={() => filterByLetter("S")}>S</Button>
				<Button variant="text" color={filterLetter === "T" ? "secondary" : "primary"} onClick={() => filterByLetter("T")}>T</Button>
				<Button variant="text" color={filterLetter === "U" ? "secondary" : "primary"} onClick={() => filterByLetter("U")}>U</Button>
				<Button variant="text" color={filterLetter === "V" ? "secondary" : "primary"} onClick={() => filterByLetter("V")}>V</Button>
				<Button variant="text" color={filterLetter === "W" ? "secondary" : "primary"} onClick={() => filterByLetter("W")}>W</Button>
				<Button variant="text" color={filterLetter === "X" ? "secondary" : "primary"} onClick={() => filterByLetter("X")}>X</Button>
				<Button variant="text" color={filterLetter === "Y" ? "secondary" : "primary"} onClick={() => filterByLetter("Y")}>Y</Button>
				<Button variant="text" color={filterLetter === "Z" ? "secondary" : "primary"} onClick={() => filterByLetter("Z")}>Z</Button>
			</Box>
		</>
	);
};

const GamesPage = props => {
	const {
		signOut, loadGames, games, loadRecommendedGames, recommendedGames, playGame, playRecommendedGame, playedGame, filterGames,
		isGamesLoading, isRecommendedGamesLoading, offset, totalCount, filter, filterLetter, filterByLetter, clearFilter, resetState
	} = props;
	const { classes } = useStyles();
	const navigate = useNavigate();

	useEffect(() => {
		if (getCurrentUser() == null) {
			navigate("/auth/sign-in");
			return;
		}

		loadGames();
		loadRecommendedGames();
		
		return () => {
			resetState();
		};
	}, []);

	return (
		<>
			<Headerbar signOut={signOut} />
			<Layout>
				<Toolbar id="back-to-top-anchor" />
				<Box className={classes.gamePage}>
					<PlaySnackbar playedGame={playedGame}/>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<GameFilter
								filterGames={filterGames} filter={filter}
								filterLetter={filterLetter} filterByLetter={filterByLetter}
								clearFilter={clearFilter} />
						</Grid>
						<Grid item xs={8} className={classes.gameList} >
							<GameList
								games={games} loadGames={loadGames}
								playGame={playGame} isGamesLoading={isGamesLoading}
								offset={offset} totalCount={totalCount}
							/>
						</Grid>
						<Grid item xs={4}>
							<Box sx={{ paddingLeft: "10px", paddingTop: "16px" }}>
								<Typography variant="h6">Препоръчани:</Typography>
								<RecommendedGameList
									recommendedGames={recommendedGames}
									isRecommendedGamesLoading={isRecommendedGamesLoading}
									playGame={playRecommendedGame} />
							</Box>
						</Grid>
					</Grid>
					<ScrollTop>
						<Fab size="small" aria-label="scroll back to top">
							<KeyboardArrowUpIcon />
						</Fab>
					</ScrollTop>
				</Box>
			</Layout>
		</>

	);
};

export default connect(
	(state) => state.games,
	(dispatch) => bindActionCreators(actionCreators, dispatch)
)(GamesPage)