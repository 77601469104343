import { configureStore } from "@reduxjs/toolkit";
import * as App from "./AppState";
import * as Games from "./GamesState";
import * as Log from "./LogState";

export default configureStore({
    reducer: {
        app: App.reducer,
        games: Games.reducer,
        log: Log.reducer
    },
});