import React from "react";
import { makeStyles } from "tss-react/mui";
import clsx from "clsx";
import {
	Box, Button, AppBar, Toolbar, Typography
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import DiamondIcon from '@mui/icons-material/Diamond';
import { getCurrentUser } from "./services/CurrentUserResolver";

const useLayoutStyles = makeStyles({ name: "Layout" })((theme, params, classes) => ({
    mainContent: {
        flexGrow: 1,
        width: "100%",
        padding: theme.spacing(3),
        [`&.${classes.mainContentCentered}`]: {
            maxWidth: "1366px",
            margin: "auto",
        }
    },
    mainContentCentered: {},
}));

export const Layout = props => {
    const { classes } = useLayoutStyles();
    return (
        <main className={clsx(classes.mainContent, classes.mainContentCentered)}>
            {props.children}
        </main>
    );
};

export const Headerbar = props => {
	const { signOut } = props;
	const navigate = useNavigate();

	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar>
				<Toolbar>
					<DiamondIcon sx={{ mr: 2, fontSize: "42px" }} />
					<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
						{getCurrentUser()}
					</Typography>

                    <Button onClick={() => navigate("/")} color="primary" sx={{ marginRight: "15px" }}>Игри</Button> 
                    <Button onClick={() => navigate("/log")} color="primary" sx={{ marginRight: "15px" }}>История</Button>    

					<Button onClick={() => signOut(navigate)} variant="contained" color="secondary">Излез</Button>
				</Toolbar>
			</AppBar>
		</Box>
	);
};