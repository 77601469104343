import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { App } from "./App";
import store from "./store/store";
import { Provider } from "react-redux";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from '@mui/material/CssBaseline';

export const muiCache = createCache({
    "key": "mui",
    "prepend": true
});

const theme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: '#90caf9',
        },
        secondary: {
            main: '#f48fb1',
        },
        background: {
            default: '#212121',
            paper: '#424242',
        },
    },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <CacheProvider value={muiCache}>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Provider store={store}>
                <BrowserRouter forceRefresh={false}>
                    <App />
                </BrowserRouter>
            </Provider>
        </ThemeProvider>
    </CacheProvider>
);